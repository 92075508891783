import styled, { css } from 'styled-components';

import media from 'styles/media';
import {H2} from "styles/typography";
import womenIcon from 'assets/images/genderGap/icon-woman.svg'
import menIcon from 'assets/images/genderGap/icon-man.svg'
import {titleSans, mineSahft, white, serif, mineSahft1, sans, codGray, magenta, turquoise, grey, black, grime} from "styles/variables";

export const Wrapper = styled.div`
  min-height: 100vh;
  height: auto;
  width: 100%;
  background-color: ${white};
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Title = styled(H2)`
  font-size: 3.1rem;
  color: ${mineSahft};
  text-align: center;
  text-transform: uppercase;
  padding: 4rem 0;
  margin: 0;
  font-family: ${titleSans};

  ${media.xs`
    text-align: left;
    font-size: 30px;
    padding: 4.5rem 0 26px 0;
  `};
`;

export const Main = styled.div`
  width: 80%;
  margin: 0 auto 0 auto;

  @media screen and (max-width: 1366px) {
    width: 100%;
    padding: 0 55px 0 0;
  }

  @media screen and (max-width: 752px) {
    margin: 0 auto 0 auto;
    padding: 25px;
  }
`;

export const SelectSection = styled.div`
  text-align: center;
  max-width: 400px;
  margin: 0 auto 0 auto;
  position: relative;
  z-index: 2;
`;

export const CountryName = styled.h3`
  text-align: center;
  font-size: 2.5rem;
  font-family: ${serif};
  color: ${mineSahft1};
  margin-top: 50px;
  margin-bottom: 25px;
  font-weight: 400;

  ${media.xs`
    text-align: left;
    font-size: 30px;
    margin-top: 26px;
  `};
`;

export const ChartWrapper = styled.div`
  width: 70%;
  margin: 0 auto;

  ${media.sm`
    width: 80%;
  `};

  ${media.xs`
    width: 100%;
  `};
`;

export const ChartLabel = styled.div`
  font-family: ${sans};
  font-size: 14px;
  color: ${codGray};
  margin-bottom: 15px;
  font-weight: 400;
`;

const smallValueStyles = css`
  position: absolute;
  left: -90px;
  color: ${turquoise};
  top: 50%;
  transform: translateY(-50%);
`;

const mediumValueStyles = css`
  position: absolute;
  left: -65px;
  color: ${turquoise};
  top: 50%;
  transform: translateY(-50%);
`;

export const ChartFill = styled.div`
  width: ${props => props.value || 0}%;
  transition: width 0.4s ease-in;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding-right: 0;
  font-size: 2.8rem;
  font-family: ${titleSans};
  font-weight: 700;
  background-color: ${turquoise};
  span {
    @media screen and (min-width: 752px) and (max-width: 1023px) {
      padding-right: 20px;
      ${props => props.value < 45 && mediumValueStyles};
    }
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      padding-right: 30px;
      ${props => props.value < 43 && smallValueStyles};
    }
    @media screen and (min-width: 1281px) {
      padding-right: 50px;
      ${props => props.value < 30 && smallValueStyles};
    }

    ${media.sm`
      padding-top: 12px;
    `};

    ${media.xs`
      display: none;
    `};
  }

  ${media.xs`
    font-size: 22px;
    align-items: flex-end;
    justify-content: flex-start;
  `};

  ${media.sm`
    font-size: 30px;
  `};
`;

const smallGapStyles = css`
  span {
    display: none;
  }
`;

export const ChartGap = styled.div`
  background-color: ${magenta};
  width: ${props => props.value || 0}%;
  transition: width 0.4s ease-in;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-family: ${sans};
  ${props => props.value < 5 && smallGapStyles};
  
  span {
    transform: rotate(-90deg);
    -ms-writing-mode: tb-lr;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding-left: 13px;
    }
  }


  ${media.xs`
    font-size: 10px;
  `};
`;

export const Chart = styled.div`
  font-family: ${sans};
  font-size: 14px;
  color: ${white};
  font-weight: 400;
  background-color: ${grey};
  margin-bottom: 25px;
  height: 100px;
  position: relative;
  display: flex;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: transparent url(${props => props.sex === 'men' ? menIcon : womenIcon}) no-repeat;
    background-size: contain;
    background-position: 15px 6px;
    ${media.xs`
      display: none;
    `};
  }


  &:last-child {
    margin-bottom: 0;
  }

  ${media.xs`
    height: 26px;
    background-position: 5px 6px;
    background: ${grey};
  `};
`;

export const ChartText = styled.div`
  position: absolute;
  left: 80px;
  bottom: 25px;
  max-width: 95px;
  line-height: 1.4;
  z-index: 1;

  ${media.xs`
    font-size: 14px;
    left: 50px;
    bottom: initial;
    top: 5px;
    display: none;
  `};

  ${media.sm`
    left: 85px;
  `};
`;

export const DataSource = styled.div`
  margin-top: 50px;
  color: ${black};
  font-family: ${sans};
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;

  ${media.xs`
    text-align: left;
    font-size: 13px;
  `};
`;

export const DataText = styled.div`
  color: ${grime};
  font-family: ${sans};
  font-size: 14px;
  text-align: center;
  width: 70%;
  margin: 0 auto 20px auto;
  line-height: 1.6;

  ${media.xs`
    text-align: left;
    width: 100%;
    font-size: 12px;
  `};
`;

export const Value = styled.div`
  color: ${turquoise};
  font-family: ${titleSans};
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const ValueType = styled.div`
  font-family: ${sans};
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
`;