import React from 'react';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import { FormattedHTMLMessage } from 'react-intl';
import InfoBox from 'components/InfoBox';
import Collage from 'components/Collage';
import { Wrapper, CollageWrapper } from './styled';

const collageLayers = [
  'equality/sewing-1',
  'equality/sewing-2',
  'equality/sewing-3'
];

const Block = () => (
  <Wrapper>
    <Outer>
      <Inner>
        <Row>
          <Col xs={12} smOffset={0} sm={6}>
            <InfoBox>
              <FormattedHTMLMessage id="equality_infoDiscrimination" />
            </InfoBox>
          </Col>
        </Row>
        <Row>
          <Col xs={12} smOffset={4} sm={4}>
            <CollageWrapper>
              <Collage
                imageStyle={{
                  width: { lg: '1000px', md: '900px', sm: '450px', xs: '100%' }
                }}
                layers={collageLayers}
                caption={{
                  message: 'equality_imageDisabledgarmentCaption',
                  colProps: {
                    xs: 12,
                    sm: 8,
                    smOffset: 3,
                    md: 8,
                    lg: 6,
                    mdOffset: 5
                  }
                }}
              />
            </CollageWrapper>
          </Col>
        </Row>
      </Inner>
    </Outer>
  </Wrapper>
);

export default Block;
