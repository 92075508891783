import React from 'react';
import { FormattedMessage } from 'react-intl';
import { gallup } from 'config/equality';
import { Legend, LegendItem } from './styled';

const { colors, labels } = gallup.chart;

const LegendRow = () => (
  <Legend>
    {labels.map((label, i) => (
      <LegendItem key={label} color={colors[i]}>
        <FormattedMessage id={label} />
      </LegendItem>
    ))}
  </Legend>
);

export default LegendRow;
