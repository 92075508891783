import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  position: relative;
  display: inline-block;
  min-height: 45rem;
  margin: 10rem 0;
  width: 100%;
  height: auto;

  ${media.md`
    min-height: 40rem;
  `}

  ${media.sm`
    min-height: 35rem;
    margin: 5rem 0;
  `}

  ${media.portrait`
    margin: 5rem 0;
    min-height: 48rem;
  `}

  ${media.xs`
    margin: 4rem 0;
    height: auto;
    min-height: initial;
  `};
`;

export const CollageWrapper = styled.div`
  position: absolute;
  display: inline-block;
  width: 50%;
  height: 100%;
  top: 12%;

  ${media.sm`
    left: initial;
    right: 0;
    height: 70%;
  `};

  ${media.portrait`
    top: 5rem;
    left: intial;
    right: 5%;
    height: 50%;
  `};

  ${media.xs`
    right: initial;
    width: 100%;
    position: relative;
    display: inline-block;
    height: auto;
  `};
`;

export const Spacer = styled.div`
  height: 20rem;

  ${media.sm`
    height: 20rem;
  `};

  ${media.xs`
    display: none;
  `};
`;
