import React from 'react';
import styled, { css } from 'styled-components';
import { h1Mixin, h3Mixin } from 'styles/typography';
import { magenta, black } from 'styles/variables';
import Tear from 'components/Tear';
import { Outer, Inner } from 'containers/Wrapper';
import CalloutStampBlock from 'modules/CalloutStamp';
import media from 'styles/media';

export const Wrapper = styled(Outer)`
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 10rem 0;

  ${media.sm`
    margin: 5rem 0 0;
  `};
`;

export const InnerWrapper = styled(Inner)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  ${h1Mixin};
  color: ${magenta};
  letter-spacing: -3px;
  margin: 0;
`;

export const Subtitle = styled.h3`
  ${h3Mixin};
  color: ${black};
  font-weight: 600;
  margin: 0 0 5rem;

  ${media.sm`
    margin: 0 0 2rem;
  `};
`;

export const Callout = styled(props => <CalloutStampBlock {...props} />)`
  margin: 4rem 0 3rem;
  padding-right: -5rem;
`;

export const Portraits = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 400px;
  padding: 0 10rem 0 0;

  ${media.md`
    padding: 0 10rem 0 0rem;
  `};

  ${media.sm`
    min-height: 300px;
    padding: 0 5rem 0 0;
  `}
`;

const getPortraitZindex = (numItems, index) => {
  if (index + 1 < numItems / 2) {
    return index;
  }
  return numItems - index;
};

export const PortraitWrapper = styled.div`
  display: inline-block;
  position: relative;
  ${props => css`
    width: ${props.size}%;
    z-index: ${getPortraitZindex(props.numberItems, props.index)};
  `};
  pointer-events: none;
`;

export const SVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  z-index: inherit;
  pointer-events: none;

  polygon {
    cursor: pointer;
  }
`;

export const Portrait = styled.img`
  height: 400px;
  width: auto;
  filter: gray;
  filter: grayscale(100%);
  transition: filter 500ms ease, opacity 500ms ease;
  pointer-events: none;
  opacity: ${props => (props.loaded ? 1 : 0)};

  ${media.md`
    height: 350px;
  `};

  ${media.sm`
    height: 225px;
  `};
`;

export const BottomTear = styled(Tear)`
  z-index: 10;
  pointer-events: none;
`;
