import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import VisibilitySensor from 'react-visibility-sensor';
import Details from 'components/Details';
import Modal from 'components/Modal';
import { portraits } from 'config/equality';
import getContentBySubstring from 'utils/getContentBySubstring';
import Portrait from './Portrait';
import {
  Wrapper,
  InnerWrapper,
  Portraits,
  PortraitWrapper,
  BottomTear,
  Title,
  Subtitle,
  Callout
} from './styled';

const { maps } = portraits;

class PortraitsModule extends Component {
  static propTypes = {
    portraits: PropTypes.arrayOf(PropTypes.string),
    intl: PropTypes.shape({
      messages: PropTypes.shape.isRequired
    }).isRequired
  };

  static defaultProps = {
    portraits: ['']
  };

  state = {
    currentPortrait: 0,
    showModal: false,
    isVisible: false
  };

  getDelay = () => {
    const index = Math.floor(Math.random() * this.intervals.length);
    const delay = this.intervals.splice(index, 1)[0];
    return delay;
  };

  intervals = this.props.portraits.map((portrait, i) => 250 * i + 1);

  handleOpenModal = index => {
    this.setState(
      {
        currentPortrait: index
      },
      () => {
        this.setState({
          showModal: true
        });
      }
    );
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  handleVisible = isVisible => {
    if (!this.state.isVisible) {
      this.setState({
        isVisible
      });
    }
  };

  renderModal = () => {
    const { currentPortrait, showModal } = this.state;
    const {
      intl: { messages }
    } = this.props;
    return (
      <Modal showModal={showModal} onRequestClose={this.handleCloseModal}>
        <Details
          figureProps={{
            image: `equality/detail-${currentPortrait}`,
            caption: `equality_portrait${currentPortrait}Caption`
          }}
          messages={getContentBySubstring(
            messages,
            `equality_portrait${currentPortrait}Detail`
          )}
        />
      </Modal>
    );
  };

  render() {
    const { showModal, isVisible } = this.state;
    return (
      <Fragment>
        <Wrapper>
          <InnerWrapper>
            <Title>
              <FormattedMessage id="equality_calloutPortraitsTitle" />
            </Title>
            <Callout
              innerStyle={{ padding: 0 }}
              stamp="fingerprint"
              calloutId="equality_calloutPortraitsBody"
              subtextId="equality_calloutPortraitsSub"
            />
            <Subtitle>
              <FormattedMessage id="equality_calloutPortraitsSubtitle" />
            </Subtitle>
            <VisibilitySensor
              delayedCall
              resizeCheck
              onChange={this.handleVisible}
            >
              <Portraits>
                {isVisible
                  ? this.props.portraits.map((portrait, i) => (
                      <PortraitWrapper
                        key={portrait}
                        numberItems={this.props.portraits.length}
                        size={100 / this.props.portraits.length}
                        index={i}
                        onClick={() => this.handleOpenModal(i)}
                      >
                        <Portrait
                          id={portrait}
                          delay={this.getDelay()}
                          areas={maps[i]}
                        />
                      </PortraitWrapper>
                    ))
                  : null}
                <BottomTear />
              </Portraits>
            </VisibilitySensor>
          </InnerWrapper>
        </Wrapper>

        {showModal ? this.renderModal() : null}
      </Fragment>
    );
  }
}

export default injectIntl(PortraitsModule);
