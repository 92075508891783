import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Outer, Inner } from 'containers/Wrapper';
import { Row } from 'react-styled-flexboxgrid';
import InfoBox from 'components/InfoBox';
import Chart from './Chart';
import Legend from './Legend';
import Labels from './Labels';
import { Wrapper, Column } from './styled';

const messages = [
  'equality_visWomen3Body',
  'equality_visWomen4Body',
  'equality_visWomen5Body',
  'equality_visWomen6Body',
  'equality_visWomen7Body'
];

function Gallup({ intl: { locale } }) {
  return (
    <Wrapper>
      <Outer>
        <Inner>
          <Row center="xs">
            <Column colOrder={{ xs: 0, sm: 0 }} xs={12} sm={8} md={5}>
              <Labels locale={locale} />
            </Column>
            <Column xs={0} smOffset={1} sm={5} mdOffset={1} md={5} />
            <Column colOrder={{ xs: 1, sm: 1 }} xs={12} sm={8} md={5}>
              <Chart />
            </Column>
            <Column
              colOrder={{ xs: 3, sm: 3 }}
              xs={12}
              smOffset={0}
              sm={8}
              mdOffset={1}
              md={5}
            >
              <InfoBox type="step" messages={messages} />
            </Column>
            <Column colOrder={{ xs: 2, sm: 2 }} xs={12} sm={8} md={5}>
              <Legend />
            </Column>
            <Column smOffset={1} sm={5} md={5} />
          </Row>
        </Inner>
      </Outer>
    </Wrapper>
  );
}

Gallup.propTypes = {
  intl: PropTypes.shape({}).isRequired
};

export default injectIntl(Gallup);
