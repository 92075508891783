import React from 'react';
import { gallup } from 'config/equality';
import { Bars, Bar, Segment } from './styled';

const { colors, men, women } = gallup.chart;

const getSegment = (color, i, segment) => (
  <Segment key={segment[i]} color={color} size={`${segment[i]}%`}>
    <span className="segment">{segment[i]}</span>
  </Segment>
);

const Chart = () => (
  <Bars>
    <Bar>
      {colors.map((color, i) => getSegment(color, i, women))}
      <Segment color="white" />
    </Bar>
    <Bar>
      {colors.map((color, i) => getSegment(color, i, men))}
      <Segment color="white" />
    </Bar>
  </Bars>
);

Chart.propTypes = {};

export default Chart;
