/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import MediaQuery from 'react-responsive';

import Select from 'components/Select';
import {
  Chart,
  ChartFill,
  ChartGap,
  ChartLabel,
  ChartText,
  ChartWrapper,
  CountryName,
  DataSource,
  DataText,
  Main,
  SelectSection,
  Title,
  Wrapper,
  ValueType,
  Value
} from 'modules/GenderGap/styled';
import breakpoints from 'styles/breakpoints';

class GenderGap extends React.Component {
  state = {
    selectedOption: null
  };

  getSelectOptions = items => {
    const {
      countries,
      intl: { locale: language }
    } = this.props;
    const countriesAvailable = items.map(({ node }) => node.country);

    return countries[language].filter(
      ({ value }) => countriesAvailable.indexOf(value) !== -1
    );
  };

  getValueByCountry = (items, sex) => {
    const {
      selectedOption: { value }
    } = this.state;
    const itemByCountry = items.filter(item => item.node.country === value)[0];

    return parseFloat(itemByCountry.node[sex]).toFixed(1);
  };

  getAverageValueWorld = (items, sex) => {
    const worldAverage = items.filter(item => item.node.country === 'X01')[0];
    return parseFloat(worldAverage.node[sex]).toFixed(1)
  };

  allowedLanguages = ['en', 'fr', 'es'];

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  render() {
    const { selectedOption } = this.state;
    const {
      intl: { locale: language }
    } = this.props;

    if (!this.allowedLanguages.includes(language)) return null;

    return (
      <StaticQuery
        query={graphql`
          query GenderGapQuery {
            allGenderGap {
              edges {
                node {
                  country
                  men
                  women
                }
              }
            }
          }
        `}
        render={data => {
          const {
            allGenderGap: { edges: items }
          } = data;
          const options = this.getSelectOptions(items);
          const valueMen = selectedOption
            ? this.getValueByCountry(items, 'men')
            : this.getAverageValueWorld(items, 'men');
          const valueWomen = selectedOption
            ? this.getValueByCountry(items, 'women')
            : this.getAverageValueWorld(items, 'women');
          return (
            <Wrapper>
              <Main>
                <Title><FormattedMessage id="equality_visGapTitle" /></Title>

                <SelectSection>
                  <Select
                    value={selectedOption}
                    isClearable
                    placeholder={<FormattedMessage id="equality_visGapSelect" />}
                    onChange={this.handleChange}
                    options={options}
                    noOptionsMessage={() => <FormattedMessage id="interface_noOptions" />}
                  />
                </SelectSection>

                <CountryName>
                  {selectedOption ? selectedOption.label : <FormattedMessage id="equality_visGapWorld" />}
                </CountryName>

                <ChartWrapper>

                  <ChartLabel><FormattedMessage id="equality_visGapRatesLabel" /></ChartLabel>

                  <MediaQuery maxDeviceWidth={breakpoints.sm * 16}>
                    <ValueType>Women</ValueType>
                    <Value>{valueWomen}</Value>
                  </MediaQuery>
                  <Chart sex="women">
                    <ChartText><FormattedMessage id="equality_visGapWomen" /></ChartText>
                    <ChartFill value={valueWomen}>
                      <span>{valueWomen}</span>
                    </ChartFill>
                    {valueMen > valueWomen && (
                      <ChartGap value={valueMen - valueWomen}><span><FormattedMessage id="equality_visGapGap" /></span></ChartGap>
                    )}
                  </Chart>

                  <MediaQuery maxDeviceWidth={breakpoints.sm * 16}>
                    <ValueType>Men</ValueType>
                    <Value>{valueMen}</Value>
                  </MediaQuery>
                  <Chart sex="men">
                    <ChartText><FormattedMessage id="equality_visGapMen" /></ChartText>
                    <ChartFill value={valueMen}>
                      <span>{valueMen}</span>
                    </ChartFill>
                    {valueWomen > valueMen && (
                      <ChartGap value={valueWomen - valueWomen}><span><FormattedMessage id="equality_visGapGap" /></span></ChartGap>
                    )}
                  </Chart>
                </ChartWrapper>

                <DataSource><FormattedMessage id="equality_visGapNote" /></DataSource>
                <DataText>
                  <FormattedMessage id="equality_visGapDefinition" />
                </DataText>
              </Main>
            </Wrapper>
          );
        }}
      />
    );
  }
}

GenderGap.propTypes = {
  countries: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: intlShape.isRequired
};

export default injectIntl(GenderGap);
