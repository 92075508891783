import React from 'react';
import PropTypes from 'prop-types';
import man from 'assets/images/man-icon.svg';
import woman from 'assets/images/woman-icon.svg';
import { FormattedMessage } from 'react-intl';
import { Labels, Label, Icon } from './styled';

const LabelsRow = ({ locale }) => (
  <Labels locale={locale}>
    <Label>
      <FormattedMessage id="equality_visWomen1Body" />
      <Icon src={woman} />
    </Label>
    <Label>
      <FormattedMessage id="equality_visWomen2Body" />
      <Icon src={man} />
    </Label>
  </Labels>
);

LabelsRow.propTypes = {
  locale: PropTypes.string.isRequired
}

export default LabelsRow;
