/* eslint-disable react/prefer-stateless-function */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import CloudinaryImage from 'components/Image';
import debounce from 'lodash/debounce';
import { Portrait, SVG } from './styled';


export default class SinglePortrait extends PureComponent {
  static propTypes = {
    delay: PropTypes.number,
    id: PropTypes.string,
    areas: PropTypes.arrayOf(
      PropTypes.shape({
        shape: PropTypes.string,
        coords: PropTypes.string
      })
    )
  };

  static defaultProps = {
    delay: 0,
    id: '',
    areas: [
      {
        shape: 'poly',
        coords: '1, 2, 3, 4'
      }
    ]
  };

  state = {
    loaded: false,
    hover: false,
    width: 1,
    height: 1,
    naturalWidth: 1,
    naturalHeight: 1
  };

  componentDidMount() {
    window.addEventListener('resize', this.debounceSetImageSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceSetImageSize);
  }

  setImageSize = () => {
    this.setState({
      width: this.imageRef.current.clientWidth,
      height: this.imageRef.current.clientHeight,
      naturalHeight: this.imageRef.current.naturalHeight,
      naturalWidth: this.imageRef.current.naturalWidth
    });
  };

  setLoaded = () => {
    this.setState({
      loaded: true
    });
  };

  imageRef = React.createRef();

  debounceSetImageSize = () => debounce(this.setImageSize, 1000);

  handleLoad = () => {
    const { delay } = this.props;
    this.setImageSize();
    window.setTimeout(this.setLoaded, delay);
  };

  handleMouseEnter = () => {
    this.setState({
      hover: true
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hover: false
    });
  };

  render() {
    const { id, areas } = this.props;
    const {
      height,
      width,
      naturalHeight,
      naturalWidth,
      hover,
      loaded
    } = this.state;
    return (
      <Fragment>
        <Portrait
          loaded={loaded}
          src={CloudinaryImage.getImageUrlById(id)}
          ref={this.imageRef}
          onLoad={this.handleLoad}
          style={hover ? { filter: 'grayscale(0)' } : null}
        />
        <SVG
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform={`scale(${width / naturalWidth}, ${height /
              naturalHeight})`}
          >
            <polygon
              pointerEvents="visible"
              fill="none"
              stroke="none"
              points={areas[0].coords}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            />
          </g>
        </SVG>
      </Fragment>
    );
  }
}
