import React from 'react';
import InfoCollage from 'modules/InfoCollage';

const collageLayers = [
  'equality/lgbti-1',
  'equality/lgbti-2',
  'equality/lgbti-3'
];

const Block = () => (
  <InfoCollage
    collage={{
      layers: collageLayers,
      caption: {
        message: 'equality_imageTranswomenCaption',
        colProps: {
          xs: 12,
          sm: 5,
          md: 5,
          mdOffset: 2,
          lgOffset: 2
        },
        voffset: {
          lg: '4rem',
          md: '4rem',
          sm: '2rem',
          xs: '0'
        }
      },
      colProps: {
        xs: 12,
        md: 10,
        lg: 11,
        mdOffset: -1,
        lgOffset: -2,
      }
    }}
    infoBoxes={[
      {
        message: 'equality_infoResearchnineBody',
        colProps: {
          xs: 12,
          smOffset: 5,
          sm: 6,
          mdOffset: 5,
          md: 5,
          lgOffset: 6
        }
      },
      {
        message: 'equality_infoDomino',
        infoBox: {
          scroll: true,
          size: { lg: '32rem', md: '25rem' }
        },
        colProps: {
          xs: 12,
          sm: 6,
          smOffset: 5,
          md: 6,
          mdOffset: 5,
          lgOffset: 6
        },
        justify: 'flex-end'
      }
    ]}
    voffset={{
      xs: '0',
      sm: '16rem 0 16rem',
      md: '12rem 0 20rem',
      lg: '20rem 0 24rem'
    }}
  />
);

export default Block;

/*
const Block = () => (
  <Wrapper>
    <Outer>
      <Inner>
        <Row reverse>
          <Col xs={12} smOffset={0} sm={8} md={6}>
            <InfoBox>
              <FormattedHTMLMessage id="equality_infoResearchnineBody" />
            </InfoBox>
          </Col>
          <Col xs={12} smOffset={-2} sm={6} md={8}>
            <Spacer />
            <CollageWrapper>
              <Collage
                layers={collageLayers}
                imageStyle={{
                  width: { lg: '1100px', md: '900px', sm: '900px', xs: '100%' }
                }}
                caption="equality_imageTranswomenCaption"
                captionStyle={{
                  size: '60%'
                }}
              />
            </CollageWrapper>
          </Col>
        </Row>
        <Row>
          <Col xs={12} smOffset={5} sm={7} mdOffset={6} md={6}>
            <BottomSpacer />
            <InfoBox
              scroll
              size={{
                lg: '33rem',
                md: '23rem'
              }}
            >
              <FormattedHTMLMessage id="equality_infoDomino" />
            </InfoBox>
          </Col>
        </Row>
      </Inner>
    </Outer>
  </Wrapper>
);

 */
