import styled from 'styled-components';
import { white, titleSans, dustyGray } from 'styles/variables';
import matchColor from 'utils/matchColor';
import { h2Mixin, captionMixin } from 'styles/typography';
import { Col } from 'react-styled-flexboxgrid';
import media from 'styles/media';
import breakpoints from 'styles/breakpoints';

export const Column = styled(Col)`
  ${props =>
    Object.keys(breakpoints).map(
      point => media[point]`order:${props.colOrder[point]}`
    )};
`;

Column.defaultProps = {
  colOrder: {
    lg: 0,
    md: 0,
    sm: 0,
    xs: 0
  }
};

export const Wrapper = styled.figure`
  direction: ltr;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  margin: 5rem 0;

  ${media.sm`
    min-height: none;
    height: auto;
  `};

  p {
    ${h2Mixin};
    color: black;
    font-size: 1.8rem;
    line-height: 2.4rem;

    ${media.sm`
      font-size: 1.4rem;
      line-height: 2rem;
    `};
  }
`;

export const Bars = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: relative;

  ${media.sm`
    height: 400px;
    margin: 0 0 2rem;
  `};
`;

export const Bar = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  flex-basis: 50%;

  &:first-child {
    padding-right: 10px;
  }

  &:last-child {
    padding-left: 10px;
  }
`;

const getBorderColor = color => {
  if (color === 'white') {
    return dustyGray;
  }
  return color;
};

export const Segment = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: flex-start;
  background-color: ${props => matchColor(props.color)};
  border: ${props => `1px solid ${matchColor(getBorderColor(props.color))}`};
  color: ${white};
  font-family: ${titleSans};
  font-size: 5.625rem;
  font-weight: 600;
  letter-spacing: -4px;
  flex-basis: ${props => props.size || 'auto'};
  flex-grow: 1;
  padding: 0 0 0 1rem;

  &:first-child {
    border-radius: 5% 5% 0 0;
  }

  .segment {
    display: inline-block;
    position: relative;
    line-height: 5rem;

    &:after {
      content: '%';
      font-size: 2rem;
      position: absolute;
      line-height: 2rem;
      top: 2px;
      font-weight: 500;
      margin: 0 0 0 5px;
    }
  }

  ${media.xs`
    font-size: 5rem;
  `};
`;

export const Legend = styled.figcaption`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 0 0;

  ${media.sm`
    padding: 0;
    margin: 0 0 2rem;
  `};
`;

export const LegendItem = styled.div`
  ${captionMixin};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
  width: 100%;

  &:before {
    content: '';
    height: 15px;
    flex: 0 0 15px;
    border-radius: 50%;
    position: relative;
    margin: 0 5px;
    display: inline-block;
    background-color: ${props => matchColor(props.color)};
  }

  &:last-child {
    &:before {
      border: 0.5px solid ${dustyGray};
    }
  }
`;

LegendItem.defaultProps = {
  color: 'white'
};

export const Labels = styled(Bars)`
  direction: ${props => (props.locale === 'ar' ? 'rtl' : 'ltr')};
  padding: 0 0 1rem;

  ${media.sm`
    height: auto;
    margin: 0;
  `};
`;

export const Label = styled(Bar)`
  font-family: ${titleSans};
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -2px;
  color: black;
  flex-flow: row nowrap;
  align-items: flex-end;

  ${media.xs`
    font-size: 1.5rem;
    letter-spacing: -1px;
  `};
`;

export const Icon = styled.img`
  height: 70px;
  width: 58px;
  opacity: 0.4;
  flex-basis: 50%;

  ${media.xs`
    height: 50px;
  `};
`;
