import React from 'react';
import { theme } from 'config/equality.json';
import CalloutStampBlock from 'modules/CalloutStamp';
import Portraits from 'modules/Portraits';
import InfoBoxBlock from 'modules/InfoBox';
import { withIntl } from 'i18n';
import Template from 'modules/ChapterTemplate';
import Videos from 'modules/Videos';
import GenderGap from 'modules/GenderGap';
import Conclusion from 'modules/Conclusion';
import countries from 'config/conventions/countries.json';
import Block3Collage from 'blocks/equality/Block-3-Collage';
import Block4Collage from 'blocks/equality/Block-4-Collage';
import Block5Collage from 'blocks/equality/Block-5-Collage';
import Responsive from 'containers/Responsive';
import Gallup from 'modules/Gallup';
import isIE11 from 'utils/isIE11';

const portraits = number => {
  const p = [];
  for (let i = 0; i < number; i += 1) {
    p.push(`equality/portraits-${i}`);
  }
  return p;
};

const Equality = () => (
  <Template theme={theme}>
    <main>
      <Videos videos={['equality/videoSreela', 'equality/videoLaurel']} />
      <CalloutStampBlock
        stamp="pinkcircle"
        calloutId="equality_calloutHundreds"
      />
      <Block3Collage />
      <Block4Collage />
      <Block5Collage />
      <Responsive only="desktop">
        {isIE11() ? null : <Portraits portraits={portraits(8)} />}
      </Responsive>
      <InfoBoxBlock
        infobox={{
          scroll: true,
          size: {
            lg: '35rem',
            md: '22rem'
          }
        }}
        id="equality_infoBreakingexpectBody1"
        col={{
          xs: 12,
          sm: 10,
          smOffset: 1,
          md: 6,
          mdOffset: 3
        }}
      />
      <Gallup />
      <CalloutStampBlock
        stamp="fingerprint"
        calloutId="equality_calloutDespiteprog"
        subtextId="equality_calloutDespiteProgSub"
      />
      <GenderGap countries={countries} />
      <InfoBoxBlock
        id="equality_infoIlsdiscrimination"
        col={{
          xs: 12,
          sm: 8,
          smOffset: 2,
          md: 6,
          mdOffset: 3
        }}
      />
      <Videos videos={['equality/videoChamorro', 'equality/videoCarling']} />
      <Conclusion
        mainTextId="equality_calloutConclusion1"
        subTextId="equality_calloutConclusion2"
      />
    </main>
  </Template>
);

export default withIntl(Equality);
