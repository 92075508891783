import React from 'react';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import { FormattedHTMLMessage } from 'react-intl';
import InfoBox from 'components/InfoBox';
import Collage from 'components/Collage';
import { Wrapper, CollageWrapper, Spacer } from './styled';

const collageLayers = [
  'equality/migrant-1',
  'equality/migrant-2',
  'equality/migrant-3'
];

const Block = () => (
  <Wrapper>
    <Outer>
      <Inner>
        <Row>
          <Col
            xs={12}
            smOffset={-2}
            sm={5}
            mdOffset={-6}
            md={6}
            lgOffset={-6}
            lg={6}
          >
            <CollageWrapper>
              <Collage
                imageStyle={{
                  width: {
                    lg: '1000px',
                    md: '1000px',
                    sm: '600px',
                    xs: '100vw'
                  }
                }}
                layers={collageLayers}
                caption={{
                  message: 'equality_imageMigrantreturnCaption',
                  colProps: {
                    xs: 12,
                    sm: 8,
                    smOffset: 4,
                    md: 8,
                    mdOffset: 5,
                    lgOffset: 4,
                    lg: 7,
                  },
                  voffset: {
                    xs: '0',
                    sm: '1rem',
                    md: '3rem',
                    lg: '2rem'
                  }
                }}
              />
            </CollageWrapper>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            smOffset={6}
            sm={6}
            mdOffset={6}
            md={6}
            lgOffset={6}
            lg={6}
          >
            <Spacer />
            <InfoBox>
              <FormattedHTMLMessage id="equality_infoConsequencesBody1" />
            </InfoBox>
          </Col>
        </Row>
      </Inner>
    </Outer>
  </Wrapper>
);

export default Block;
